import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "assets/styles/GlobalStyles";
import { theme } from "assets/styles/theme";
import { Navigation } from "components/Navigation/Navigation";
import { Footer } from "components/Footer/Footer";
import Lenis from "@studio-freight/lenis";
import { useDarkMode } from "usehooks-ts";
import "assets/styles/normalize.css";
import "assets/styles/bootstrap-grid.min.css";
import { StyledMain } from "./MainTemplate.styled";
import { FloatingAlert } from "../components/FloatingAlert/FloatingAlert";
import { shouldShowAlert } from "../utils/alert";

export function MainTemplate({ children }) {
  const { isDarkMode } = useDarkMode();
  const showFloatingAlert = shouldShowAlert();

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - 2 ** (-10 * t)),
      direction: "vertical",
      gestureDirection: "vertical",
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 1,
      infinite: false,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme[isDarkMode ? "dark" : "light"]}>
        <GlobalStyle />
        <Navigation />
        <StyledMain>{children}</StyledMain>
        <Footer />
        {showFloatingAlert && <FloatingAlert />}
      </ThemeProvider>
    </>
  );
}

MainTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};
