import styled from "styled-components";

export const Paragraph = styled.p`
  font-family: ${({ theme }) => theme.font.family.montserrat};
  font-weight: ${({ bold }) => (bold ? "500" : "400")};
  line-height: 24px;
  font-size: ${({ heading }) => (heading ? "18px" : "16px")};
  color: ${({ theme, secondary }) =>
    secondary ? theme.color.textSecondary : theme.color.text};
`;
