import { motion } from "framer-motion";
import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { Paragraph } from "components/Paragraph/Paragraph";
import { VACATION_END_DATE, formatDateToPolish } from "../../utils/alert";

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: ${({ theme }) => theme.color.background};
  border: 2px ${({ theme }) => theme.color.highlight} solid;
  z-index: 99999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: calc(100vw - 64px);
  width: 100%;

  ${({ theme }) => theme.mq.tablet} {
    max-width: calc(50vw);
  }

  ${({ theme }) => theme.mq.desktop} {
    max-width: calc(30vw);
  }

  ${({ theme }) => theme.mq.bigDesktop} {
    max-width: calc(25vw);
  }

  ${({ theme }) => theme.mq.huge} {
    max-width: calc(20vw);
  }
`;

const StyledHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: ${({ theme }) => theme.color.background};
  padding: 8px 8px 8px 16px;
  gap: 24px;
  border-bottom: ${({ isOpen }) => (isOpen ? "2px" : 0)}
    ${({ theme }) => theme.color.highlight} solid;
  cursor: pointer;
`;

const PlusMinusIcon = ({ isOpen }) => {
  const theme = useTheme();

  return (
    <motion.svg
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M5 12H19"
        stroke={theme.color.highlight}
        strokeWidth="2"
        strokeLinecap="round"
      />

      <motion.path
        d="M12 5V19"
        stroke={theme.color.highlight}
        strokeWidth="2"
        strokeLinecap="round"
        animate={{ scaleY: isOpen ? 0 : 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      />
    </motion.svg>
  );
};

export const FloatingAlert = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <StyledWrapper>
      <StyledHeader
        type="button"
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Paragraph className="m-0" bold heading>
          Pracownia nieczynna do {formatDateToPolish(VACATION_END_DATE)}.
        </Paragraph>
        <PlusMinusIcon isOpen={isOpen} />
      </StyledHeader>

      <motion.div
        initial={false}
        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="overflow-hidden"
      >
        <motion.div layout style={{ padding: "16px" }}>
          <Paragraph className="m-0">
            Drodzy Klienci, informujemy, że nasza pracownia tapicerska jest
            tymczasowo zamknięta do{" "}
            <b>{formatDateToPolish(VACATION_END_DATE)}</b>.
            <br />
            Przepraszamy za niedogodności i zapraszamy ponownie po przerwie!
            <br />
            <br />
            W pilnych sprawach prosimy o kontakt mailowy.
            <br />
            <b>Dziękujemy za zrozumienie!</b>
          </Paragraph>
        </motion.div>
      </motion.div>
    </StyledWrapper>
  );
};
