export const VACATION_END_DATE = new Date(2025, 8, 30);

export const shouldShowAlert = () => new Date() < VACATION_END_DATE;

export const formatDateToPolish = (date) => {
  const months = [
    "stycznia",
    "lutego",
    "marca",
    "kwietnia",
    "maja",
    "czerwca",
    "lipca",
    "sierpnia",
    "września",
    "października",
    "listopada",
    "grudnia",
  ];

  const day = date.getDate();
  const month = date.getMonth();

  return `${day} ${months[month]}`;
};
